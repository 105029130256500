import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import heroimg from "../../assets/Shape_04.png";
export default function Newheroarea() {
  return (
    <section id="hero">
      <Container fluid>
        <Row>
          <Col
            style={{
              backgroundImage: `url( ${heroimg} )`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right bottom",
            }}
            xs={{ span: 7 }}
          ></Col>
          <Col
            xs={{ span: 5 }}
            className="d-flex flex-column justify-content-center"
          >
            <h1>👋 Hey there! I'm Adam.</h1>
            <h2 className="text-muted">I'm a software developer.</h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
