import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import dash from "../../assets/fantastictelegram.png";
import { Github } from "@icons-pack/react-simple-icons";
export default function Dashboard() {
  return (
    <Container className="work">
      <Row className="mb-3">
        <Col xs={3}>
          <h2 className="mb-0 text-uppercase projecthead">
            Internet Dashboard
          </h2>
          <h6
            className="
			 text-muted"
          >
            Full-Stack Application
          </h6>
        </Col>
        <Col>
          <h6 className="text-muted text-end">Key Features:</h6>
          <ul className="text-end ">
            <li>React Front-End</li>
            <li>Real-Time, Live Data</li>
            <li>Data Aggregations</li>
            <li>Custom API</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Carousel
            className="border border-3 border-primary rounded"
            fade
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <img className="d-block w-100" src={dash} alt="Dashboard" />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="text-md-end">
          <h1 className="text-muted text-uppercase">Try</h1>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <a
            href="https://github.com/adamfarver/speed-test-dashboard"
            target="_blank"
            className="btn btn-primary me-2 mb-2"
            rounded
          >
            <Github className="me-2" size={20} />
            Code
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="text-end">
          <h1 className="text-muted text-uppercase">What</h1>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          Who wants slow internet speeds? No one. This application is a way to
          keep tabs on your internet throughput with historical averaging and
          live tracking.
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs="3" className="text-end">
          <h1 className="text-muted text-uppercase">How</h1>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <h5>Technologies</h5>
          <h6 className="callouts">ReactJS</h6>
          <p>General Interface Library</p>
          <h6 className="callouts">SocketIO</h6>
          <p>Real-Time Communications</p>

          <h6 className="callouts">NodeJS</h6>
          <p>Base For API software</p>

          <h6 className="callouts">Speedtest-net</h6>
          <p>Speedtest Interface</p>

          <h6 className="callouts">Express</h6>
          <p>API Routing</p>

          <h6 className="callouts">Mongoose</h6>
          <p>Database Operations</p>

          <h6 className="callouts">MongoDB</h6>
          <p>Database</p>

          <h6 className="callouts">NodeCron</h6>
          <p>Speed Test Scheduling</p>
        </Col>
      </Row>
    </Container>
  );
}
