import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
// Get proper URLS for display
import imageUrl from '@sanity/image-url';
// Import Querying tool
import client from '../../sanity-client';
import {Container, Row, Col, Card, Nav} from 'react-bootstrap';
// Example items, to simulate fetching from another resources.

function Items({currentItems}) {
  const dateformatter = (date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    date = new Date(date);
    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };
  const builder = imageUrl(client);
  function urlFor(source) {
    return builder.image(source);
  }
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <Card key={item._id} className="mb-3">
            <Row className="g-0">
              <Col md={4}>
                {item.mainImage && (
                  <img
                    src={urlFor(item.mainImage).width(800).url()}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                )}
              </Col>
              <Col md={8}>
                <Card.Body>
                  <a href={`/blog/${item.slug.current}`}>
                    <h5 className="card-title">{item.title}</h5>
                  </a>
                  <Card.Text>{item.abstract}</Card.Text>
                  <small className="text-muted">
                    {dateformatter(item.publishedAt)}
                  </small>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        ))}
    </>
  );
}

function PaginatedItems({itemsPerPage}) {
  // We start with an empty list of items.
  const [items, setItems] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    console.log(client);
    client
      .fetch(
        `*[_type=='post'] | order(publishedAt desc){ title, slug, publishedAt, "mainImage":mainImage.asset->url , abstract, _id}`
      )
      .then((res) => {
        // Push your data to the static array
        setItems(res);
      })
      .catch((e) => console.log(e));
  }, []);
  useEffect(() => {
    // Fetch items from another resources.

    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <nav>
        <ReactPaginate
          activeClassName="active"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          nextClassName="page-item"
          pageClassName="page-item"
          previousClassName="page-item"
          disabledClassName="disabled"
          disabledLinkClassName="disabled"
          containerClassName="pagination justify-content-center"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </nav>
    </>
  );
}

export const output = () => {
  return (
    <div className="blog">
      <Container>
        <Row>
          <Col>
            <h1>Development Blog</h1>
            <PaginatedItems itemsPerPage={2} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
