import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import guess from "../../assets/guessnumber.png";
import { Github, Heroku } from "@icons-pack/react-simple-icons";
export default function GuessNumber() {
  return (
    <Container className="work">
      <Row className="mb-3">
        <Col xs={3}>
          <h2 className="mb-0 text-uppercase projecthead">Guess Number</h2>
          <h6
            className="
			 text-muted"
          >
            Front-End Application
          </h6>
        </Col>
        <Col>
          <h6 className="text-muted text-end">Key Features:</h6>
          <ul className="text-end ">
            <li>React Front-End</li>
            <li>Jest Testing</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Carousel
            className="border border-3 border-primary rounded"
            fade
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={guess}
                alt="Project Screenshot"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="text-md-end">
          <h1 className="text-muted text-uppercase">Try</h1>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <a
            href="https://adamfarver-guessnumber.herokuapp.com"
            target="_blank"
            className="btn btn-primary me-2 mb-2"
            rounded
          >
            <Heroku className="me-2" size={20} />
            Live Preview
          </a>
          <a
            href="https://github.com/adamfarver/guessnumber"
            target="_blank"
            className="btn btn-primary me-2 mb-2"
            rounded
          >
            <Github className="me-2" size={20} />
            Code
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="text-end">
          <h1 className="text-muted text-uppercase">What</h1>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          Guess Number is a simple application that facilitates learning number
          place values. It keeps a running list of the guesses and whether they
          are higher or lower than the target number.
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs="3" className="text-end">
          <h1 className="text-muted text-uppercase">How</h1>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <h5>Technologies</h5>
          <h6 className="callouts">ReactJS</h6>
          <p>General Interface Library</p>

          <h6 className="callouts">ReactJS Context API</h6>
          <p>App State</p>
          <h6 className="callouts">Formik</h6>
          <p>Form Inputs</p>
          <h6 className="callouts">Jest</h6>
          <p>Unit Tests</p>
        </Col>
      </Row>
    </Container>
  );
}
